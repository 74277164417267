import type { Environment } from '../../schemas/environment.js';
import { SUPPORTED_COUNTRIES } from './constants.js';

export const stagingUS: Environment = {
  countryCode: 'US',
  hostName: 'webapp.US',
  hosts: {
    account: 'account.web.us',
    listen: 'listen.web.us',
  },
  locale: 'en-US',
  objectDbBucket: 'web.listen.staging',
  markPlayedThreshold: 30,
  supportedCountries: SUPPORTED_COUNTRIES,
  terminalId: 159,
  territoryCode: 'US',
  websiteUrl: 'https://stage.iheart.com',
};

export const productionUS: Environment = {
  ...stagingUS,
  objectDbBucket: 'web.listen.prod',
  websiteUrl: 'https://www.iheart.com',
};

export const pullRequestUS: Environment = {
  ...productionUS,
  objectDbBucket: 'web.listen.staging',
};
