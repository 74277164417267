import type { Environment } from '../../schemas/environment.js';
import { SUPPORTED_COUNTRIES } from './constants.js';

export const stagingCA: Environment = {
  countryCode: 'CA',
  hostName: 'webapp.CA',
  hosts: {
    account: 'account.web.ca',
    listen: 'listen.web.ca',
  },
  locale: 'en-CA',
  objectDbBucket: 'web.listen.staging',
  markPlayedThreshold: 30,
  supportedCountries: SUPPORTED_COUNTRIES,
  terminalId: 163,
  territoryCode: 'CA',
  websiteUrl: 'https://stage.iheart.com',
};

export const productionCA: Environment = {
  ...stagingCA,
  objectDbBucket: 'web.listen.prod',
  websiteUrl: 'https://www.iheart.com',
};

export const pullRequestCA: Environment = {
  ...productionCA,
  objectDbBucket: 'web.listen.staging',
};
