import type { Features } from '../../schemas/features.js';

export const featuresBase: Features = {
  showPodcastTranscriptions: true,
  useAmpTranscription: false,
  showHomepageHero: false,
  showMarketingBanner: true,
  showPodcastSearch: false,
  podcastTritonTokenEnabled: true,
  showUserEducationTour: false,
  showRankers: true,
  lyricsLengthMaxDeviation: 15,
};
